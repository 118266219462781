export default {
  name: 'The Fieldhouse - Northvale',
  siteURL: 'https://www.njfieldhouse.com',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/fieldhouse_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#225EB3',
    mobileSignInColor: '#225EB3',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#225EB3',
    accent1Color: '#D83135',

    primary: {
      main: '#225EB3',
    },
    secondary: {
      main: '#D83135',
    },
  },
  sideNav: {
    selectedColor: '#225EB3',
  },
  icons: {
    cart: '#225EB3',
  },
  statusBar: {
    backgroundColor: '#225EB3',
  },
};
