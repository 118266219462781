export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'The Fieldhouse - Northvale',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    "Welcome to The Fieldhouse in Northvale, NJ! We are proud to provide a wide range of youth and adult sports programming from speed & agility training to camps, clinics, parties and field rentals. There's something for everyone!",
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
};
